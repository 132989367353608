@import "~@sas-te/alfabeto-tokens/src/scss/mixins";
@import "~@sas-te/alfabeto-tokens/src/scss/functions";
@import "~@sas-te/alfabeto-tokens/src/scss/variables";
@import "~@/styles/variables";
@import "~@/styles/mixins";
@import "~@/styles/media";








































































.assignments-list-item {
  @include flex-center-start;
  @include space-stack($size-s);
  padding: $size-s;
  flex-wrap: wrap;

  @include mq-xs--mf {
    flex-wrap: nowrap;
  }

  @include mq-m--mf {
    padding: $size-s $size-m;
  }

  &__details {
    flex-basis: 100%;
    max-width: 100%;

    @include mq-m--mf {
      padding-right: $size-m;
      flex-grow: 1;
      flex-basis: auto;
    }

    span {
      display: block;
      color: $color-ink-lighter;
      margin-bottom: $size-xs;
    }

    h6 {
      margin-bottom: $size-s;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      @include mq-m--mf {
        margin-bottom: 0;
      }
    }
  }

  &__release-date {
    display: flex;
    align-items: flex-end;

    ::v-deep &__icon {
      min-width: 24px;
      color: $color-ink-lighter;
      margin-right: $size-xxs;
    }

    &__text {
      white-space: nowrap;
      color: $color-ink-light;
    }
  }

  &.--secondary {
    background-image: url('../../assets/pink-mask-sm.svg') !important;

    @include mq-m--mf {
      background-image: url('../../assets/pink-mask.svg') !important;
    }

    &__details {
      box-sizing: content-box;
      padding: 0;
    }

    &.sas-box {
      min-height: 100px;
      background-size: cover !important;
      background-position: right !important;
      background-repeat:no-repeat !important;
      padding: 0 $size-s;
    }
  }
}
