@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/variables"
@import "~@/styles/mixins"
@import "~@/styles/media"












































































.assignments-container
  &__title
    font-size: $font-size-heading-5-small
    margin-bottom: $size-s
    color: $color-ink-light

    +mq-m--mf
      font-size: $font-size-heading-5

  &__list-item
    span
      color: $color-ink-lighter
      font-size: $font-size-xs

    h4
      font-size: $font-size-heading-6
      margin-top: $size-xxs
